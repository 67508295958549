// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
// import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import Link from '../components/link'
import '../components/link/style.less'

import StandardPageWrapper from '../components/standard-page-wrapper'
import '../components/standard-page-wrapper/style.less'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React
const pageSchema = {
  title: 'Impressum',
  slug: '/impressum',
  abstract: 'Impressum',
  breadcrumbs: [
    { title: 'Cover Page', slug: '' },
    { title: 'Impressum', slug: '/impressum' },
  ],
}

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Images
// ----------------------------------------------------------------------------
// export const query = graphql`
//   query {
//     launch: file(relativePath: { eq: "launch.jpg" }) {
//       ...max900
//     }
//   }
// `

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Page */
const Page = (props) => (
  <StandardPageWrapper className="" pageSchema={pageSchema} {...props}>
    <div
      className="container"
      style={{ paddingTop: '1rem', paddingBottom: '1rem' }}
    >
      <h1>Impressum</h1>
      <ul>
        <li>Compiled by Franz Fassbender</li>
        <li>First edition 2021</li>
        <li>
          ISBN: <i>Coming soon</i>
        </li>
        <li>
          Designed & Published by{' '}
          <Link to="https://www.prisma.haus/en/">Prisma</Link>
        </li>
        <li>
          Publisher's address:{' '}
          <Link to="https://goo.gl/maps/nxuUpusYneNUgqGa8">
            Aurelec / Prayogashala, Auroville 605101 Tamil Nadu, India
          </Link>
        </li>
        <li>
          Publisher's email:{' '}
          <Link to="prisma@auroville.org.in">prisma@auroville.org.in</Link>
        </li>
        <li>
          Publisher's website:{' '}
          <Link to="https://books.prisma.haus/en/">books.prisma.haus</Link>
        </li>
        <li>
          Web Book handcrafted by{' '}
          <Link to="https://design.prisma.haus/en/">Prisma Design</Link>
        </li>
      </ul>
    </div>
  </StandardPageWrapper>
)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default Page
